import EndPoints from "../config/endpoint";
import Api from "./api";

export default class PermissionRequestAPI {
  static createPermissionAPI = (data: any) =>
    Api.request({
      method: "post",
      url: EndPoints.CREATE_PERMISSION,
      data
    });

  static updatePermissionAPI = (data: { updateUserDTO: object; userID: string }) => {
    return Api.request({
      method: "put",
      url: `${EndPoints.UPDATE_PERMISSION}/${data.userID}`,
      data: data?.updateUserDTO
    });
  };

  static getPermissionAPI = (data: any) =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_PERMISSION}/${data?.id}`
    });

  static getPermissionListAPI = () =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_PERMISSION_LIST}`
    });

  static deletePermissionAPI = (data: any) =>
    Api.request({
      method: "delete",
      url: `${EndPoints.DELETE_PERMISSION}/${data.id}`
    });

  static assignPermissionAPI = (data: any) =>
    Api.request({
      method: "post",
      url: `${EndPoints.ASSIGN_PERMISSION}/${data.id}`,
      data: data.permissionDTO
    });
}
