const ACTION_HEADER = "ROLE/";

export const CREATE_ROLE = ACTION_HEADER + "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = ACTION_HEADER + "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = ACTION_HEADER + "CREATE_ROLE_FAIL";

export const RESET_CREATE_ROLE = ACTION_HEADER + "RESET_CREATE_ROLE";

export const GET_ROLE = ACTION_HEADER + "GET_ROLE";
export const GET_ROLE_SUCCESS = ACTION_HEADER + "GET_ROLE_SUCCESS";
export const GET_ROLE_FAIL = ACTION_HEADER + "GET_ROLE_FAIL";

export const RESET_GET_ROLE = ACTION_HEADER + "RESET_GET_ROLE";

export const GET_ROLE_LIST = ACTION_HEADER + "GET_ROLE_LIST";
export const GET_ROLE_LIST_SUCCESS = ACTION_HEADER + "GET_ROLE_LIST_SUCCESS";
export const GET_ROLE_LIST_FAIL = ACTION_HEADER + "GET_ROLE_LIST_FAIL";

export const RESET_GET_ROLE_LIST = ACTION_HEADER + "RESET_GET_ROLE_LIST";

export const GET_ROLE_DROPDWON_LIST = ACTION_HEADER + "GET_ROLE_DROPDWON_LIST";
export const GET_ROLE_DROPDWON_LIST_SUCCESS = ACTION_HEADER + "GET_ROLE_DROPDWON_LIST_SUCCESS";
export const GET_ROLE_DROPDWON_LIST_FAIL = ACTION_HEADER + "GET_ROLE_DROPDWON_LIST_FAIL";

export const RESET_GET_ROLE_DROPDWON_LIST = ACTION_HEADER + "RESET_GET_ROLE_DROPDWON_LIST";

export const UPDATE_ROLE = ACTION_HEADER + "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = ACTION_HEADER + "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = ACTION_HEADER + "UPDATE_ROLE_FAIL";

export const RESET_UPDATE_ROLE = ACTION_HEADER + "RESET_UPDATE_ROLE";

export const DELETE_ROLE = ACTION_HEADER + "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = ACTION_HEADER + "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = ACTION_HEADER + "DELETE_ROLE_FAIL";

export const RESET_DELETE_ROLE = ACTION_HEADER + "RESET_DELETE_ROLE";

// export const ASSIGN_PERMISSION = ACTION_HEADER + "ASSIGN_PERMISSION";
// export const ASSIGN_PERMISSION_SUCCESS = ACTION_HEADER + "ASSIGN_PERMISSION_SUCCESS";
// export const ASSIGN_PERMISSION_FAIL = ACTION_HEADER + "ASSIGN_PERMISSION_FAIL";

// export const RESET_ASSIGN_PERMISSION = ACTION_HEADER + "RESET_ASSIGN_PERMISSION";

export const GET_ROLE_LEVEL = ACTION_HEADER + "GET_ROLE_LEVEL";
export const GET_ROLE_LEVEL_SUCCESS = ACTION_HEADER + "GET_ROLE_LEVEL_SUCCESS";
export const GET_ROLE_LEVEL_FAIL = ACTION_HEADER + "GET_ROLE_LEVEL_FAIL";

export const RESET_GET_ROLE_LEVEL = ACTION_HEADER + "RESET_GET_ROLE_LEVEL";
