import { initialStateModal } from "../../constant/CommonConstant";
import {
  CREATE_ROLE,
  CREATE_ROLE_FAIL,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_SUCCESS,
  GET_ROLE,
  GET_ROLE_DROPDWON_LIST,
  GET_ROLE_DROPDWON_LIST_FAIL,
  GET_ROLE_DROPDWON_LIST_SUCCESS,
  GET_ROLE_FAIL,
  GET_ROLE_LEVEL,
  GET_ROLE_LEVEL_FAIL,
  GET_ROLE_LEVEL_SUCCESS,
  GET_ROLE_LIST,
  GET_ROLE_LIST_FAIL,
  GET_ROLE_LIST_SUCCESS,
  GET_ROLE_SUCCESS,
  RESET_CREATE_ROLE,
  RESET_DELETE_ROLE,
  RESET_GET_ROLE,
  RESET_GET_ROLE_DROPDWON_LIST,
  RESET_GET_ROLE_LEVEL,
  RESET_GET_ROLE_LIST,
  RESET_UPDATE_ROLE,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS
} from "./types";

const initialState = {
  createRole: {
    ...initialStateModal
  },
  getRole: {
    ...initialStateModal
  },
  getRoleList: {
    ...initialStateModal
  },
  getRoleDropdownList: {
    ...initialStateModal
  },
  updateRole: {
    ...initialStateModal
  },
  deleteRole: {
    ...initialStateModal
  },

  getRoleLevel: {
    ...initialStateModal
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------- CREATE_ROLE ----------------
    case CREATE_ROLE:
      return {
        ...state,
        createRole: {
          ...state.createRole,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        createRole: {
          ...state.createRole,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case CREATE_ROLE_FAIL:
      return {
        ...state,
        createRole: {
          ...state.createRole,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_CREATE_ROLE ----------------
    case RESET_CREATE_ROLE:
      return {
        ...state,
        createRole: initialState.createRole
      };

    // ---------------- GET_ROLE ----------------
    case GET_ROLE:
      return {
        ...state,
        getRole: {
          ...state.getRole,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        getRole: {
          ...state.getRole,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_ROLE_FAIL:
      return {
        ...state,
        getRole: {
          ...state.getRole,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_ROLE ----------------
    case RESET_GET_ROLE:
      return {
        ...state,
        getRole: initialState.getRole
      };

    // ---------------- GET_ROLE_LIST ----------------
    case GET_ROLE_LIST:
      return {
        ...state,
        getRoleList: {
          ...state.getRoleList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        getRoleList: {
          ...state.getRoleList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_ROLE_LIST_FAIL:
      return {
        ...state,
        getRoleList: {
          ...state.getRoleList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_ROLE_LIST ----------------
    case RESET_GET_ROLE_LIST:
      return {
        ...state,
        getRoleList: initialState.getRoleList
      };

    // ---------------- GET_ROLE_DROPDWON_LIST ----------------
    case GET_ROLE_DROPDWON_LIST:
      return {
        ...state,
        getRoleDropdownList: {
          ...state.getRoleDropdownList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_ROLE_DROPDWON_LIST_SUCCESS:
      return {
        ...state,
        getRoleDropdownList: {
          ...state.getRoleDropdownList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_ROLE_DROPDWON_LIST_FAIL:
      return {
        ...state,
        getRoleDropdownList: {
          ...state.getRoleDropdownList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_ROLE_DROPDWON_LIST ----------------
    case RESET_GET_ROLE_DROPDWON_LIST:
      return {
        ...state,
        getRoleDropdownList: initialState.getRoleDropdownList
      };

    // ---------------- UPDATE_ROLE ----------------
    case UPDATE_ROLE:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_UPDATE_ROLE ----------------
    case RESET_UPDATE_ROLE:
      return {
        ...state,
        updateRole: initialState.updateRole
      };

    // ---------------- DELETE_ROLE ----------------
    case DELETE_ROLE:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case DELETE_ROLE_FAIL:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_DELETE_ROLE ----------------
    case RESET_DELETE_ROLE:
      return {
        ...state,
        deleteRole: initialState.deleteRole
      };

    // ---------------- GET_ROLE_LEVEL ----------------
    case GET_ROLE_LEVEL:
      return {
        ...state,
        getRoleLevel: {
          ...state.getRoleLevel,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_ROLE_LEVEL_SUCCESS:
      return {
        ...state,
        getRoleLevel: {
          ...state.getRoleLevel,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_ROLE_LEVEL_FAIL:
      return {
        ...state,
        getRoleLevel: {
          ...state.getRoleLevel,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_ROLE_LEVEL ----------------
    case RESET_GET_ROLE_LEVEL:
      return {
        ...state,
        getRoleLevel: initialState.getRoleLevel
      };

    default:
      return state;
  }
};
