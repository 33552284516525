import EndPoints from "../config/endpoint";
import Api from "./api";

export default class RegionRequestAPI {
  static createRegionAPI = (data: any) =>
    Api.request({
      method: "post",
      url: EndPoints.CREATE_REGION,
      data
    });

  static updateRegionAPI = (data: { updateUserDTO: object; userID: string }) => {
    return Api.request({
      method: "put",
      url: `${EndPoints.UPDATE_REGION}/${data.userID}`,
      data: data?.updateUserDTO
    });
  };

  static getRegionAPI = (data: any) =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_REGION}/${data.id}`,

      data
    });

  static getRegionListAPI = (payload) =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_REGION_LIST}?page=${payload?.page}&name=${payload?.name}&code=${payload?.code}&user=${payload?.user}&business_unit=${payload?.businessUnit}`
    });

  static getRegionDropdownListAPI = () =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_REGION_LIST}?res=list`
    });

  static deleteRegionAPI = (data: any) =>
    Api.request({
      method: "delete",
      url: `${EndPoints.DELETE_REGION}/${data.id}`
    });
}
