import { initialStateModal } from "../../../constant/CommonConstant";
import {
  CREATE_REGION,
  CREATE_REGION_FAIL,
  CREATE_REGION_SUCCESS,
  DELETE_REGION,
  DELETE_REGION_FAIL,
  DELETE_REGION_SUCCESS,
  GET_REGION,
  GET_REGION_DROPDOWN_LIST,
  GET_REGION_DROPDOWN_LIST_FAIL,
  GET_REGION_DROPDOWN_LIST_SUCCESS,
  GET_REGION_FAIL,
  GET_REGION_LIST,
  GET_REGION_LIST_FAIL,
  GET_REGION_LIST_SUCCESS,
  GET_REGION_SUCCESS,
  RESET_CREATE_REGION,
  RESET_DELETE_REGION,
  RESET_GET_REGION,
  RESET_GET_REGION_DROPDOWN_LIST,
  RESET_GET_REGION_LIST,
  RESET_UPDATE_REGION,
  UPDATE_REGION,
  UPDATE_REGION_FAIL,
  UPDATE_REGION_SUCCESS
} from "./types";

const initialState = {
  createRegion: {
    ...initialStateModal
  },
  getRegion: {
    ...initialStateModal
  },
  getRegionList: {
    ...initialStateModal
  },
  getRegionDropdownList: {
    ...initialStateModal
  },
  updateRegion: {
    ...initialStateModal
  },
  deleteRegion: {
    ...initialStateModal
  },
  assignPermission: {
    ...initialStateModal
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------- CREATE_REGION ----------------
    case CREATE_REGION:
      return {
        ...state,
        createRegion: {
          ...state.createRegion,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case CREATE_REGION_SUCCESS:
      return {
        ...state,
        createRegion: {
          ...state.createRegion,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case CREATE_REGION_FAIL:
      return {
        ...state,
        createRegion: {
          ...state.createRegion,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_CREATE_REGION ----------------
    case RESET_CREATE_REGION:
      return {
        ...state,
        createRegion: initialState.createRegion
      };

    // ---------------- GET_REGION ----------------
    case GET_REGION:
      return {
        ...state,
        getRegion: {
          ...state.getRegion,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_REGION_SUCCESS:
      return {
        ...state,
        getRegion: {
          ...state.getRegion,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_REGION_FAIL:
      return {
        ...state,
        getRegion: {
          ...state.getRegion,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_REGION ----------------
    case RESET_GET_REGION:
      return {
        ...state,
        getRegion: initialState.getRegion
      };

    // ---------------- GET_REGION_LIST ----------------
    case GET_REGION_LIST:
      return {
        ...state,
        getRegionList: {
          ...state.getRegionList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_REGION_LIST_SUCCESS:
      return {
        ...state,
        getRegionList: {
          ...state.getRegionList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_REGION_LIST_FAIL:
      return {
        ...state,
        getRegionList: {
          ...state.getRegionList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_REGION_LIST ----------------
    case RESET_GET_REGION_LIST:
      return {
        ...state,
        getRegionList: initialState.getRegionList
      };

    // ---------------- GET_REGION_DROPDOWN_LIST ----------------
    case GET_REGION_DROPDOWN_LIST:
      return {
        ...state,
        getRegionDropdownList: {
          ...state.getRegionDropdownList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_REGION_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        getRegionDropdownList: {
          ...state.getRegionDropdownList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_REGION_DROPDOWN_LIST_FAIL:
      return {
        ...state,
        getRegionDropdownList: {
          ...state.getRegionDropdownList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_REGION_DROPDOWN_LIST ----------------
    case RESET_GET_REGION_DROPDOWN_LIST:
      return {
        ...state,
        getRegionDropdownList: initialState.getRegionDropdownList
      };

    // ---------------- UPDATE_REGION ----------------
    case UPDATE_REGION:
      return {
        ...state,
        updateRegion: {
          ...state.updateRegion,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case UPDATE_REGION_SUCCESS:
      return {
        ...state,
        updateRegion: {
          ...state.updateRegion,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case UPDATE_REGION_FAIL:
      return {
        ...state,
        updateRegion: {
          ...state.updateRegion,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_UPDATE_REGION ----------------
    case RESET_UPDATE_REGION:
      return {
        ...state,
        updateRegion: initialState.updateRegion
      };

    // ---------------- DELETE_REGION ----------------
    case DELETE_REGION:
      return {
        ...state,
        deleteRegion: {
          ...state.deleteRegion,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case DELETE_REGION_SUCCESS:
      return {
        ...state,
        deleteRegion: {
          ...state.deleteRegion,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case DELETE_REGION_FAIL:
      return {
        ...state,
        deleteRegion: {
          ...state.deleteRegion,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_DELETE_REGION ----------------
    case RESET_DELETE_REGION:
      return {
        ...state,
        deleteRegion: initialState.deleteRegion
      };

    default:
      return state;
  }
};
