import { initialStateModal } from "../../constant/CommonConstant";
import {
  SAVE_AUTH_TOKENS,
  REMOVE_AUTH_TOKENS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_FORGOT_PASSWORD,
  SET_UP_NEW_PASSWORD,
  SET_UP_NEW_PASSWORD_SUCCESS,
  SET_UP_NEW_PASSWORD_FAIL,
  RESET_SET_UP_NEW_PASSWORD,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AD_AUTHENTICATE,
  AD_AUTHENTICATE_SUCCESS,
  AD_AUTHENTICATE_FAIL,
  RESET_AD_AUTHENTICATE
} from "./types";

const initialState = {
  accessToken: "",
  refreshToken: "",
  login: {
    ...initialStateModal
  },
  forgotPassword: {
    ...initialStateModal
  },
  setUpNewPassword: {
    ...initialStateModal
  },
  adAuthenticate: {
    ...initialStateModal
  },
  idToken: null
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SAVE_AUTH_TOKENS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        fullName: action.payload.fullName,
        userType: action.payload.type
      };
    case REMOVE_AUTH_TOKENS:
      return {
        ...state,
        accessToken: "",
        refreshToken: ""
      };

    // ---------------- LOGIN ----------------
    case LOGIN:
      return {
        ...state,

        login: {
          ...state.login,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.payload,
        login: {
          ...state.login,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };

    case LOGIN_FAIL:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };

    // ---------------- FORGOT_PASSWORD ----------------
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };

    // ---------------- RESET_FORGOT_PASSWORD ----------------
    case RESET_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: initialState.forgotPassword
      };

    // ---------------- SET_UP_NEW_PASSWORD ----------------
    case SET_UP_NEW_PASSWORD:
      return {
        ...state,
        setUpNewPassword: {
          ...state.setUpNewPassword,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };

    case SET_UP_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        setUpNewPassword: {
          ...state.setUpNewPassword,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };

    case SET_UP_NEW_PASSWORD_FAIL:
      return {
        ...state,
        setUpNewPassword: {
          ...state.setUpNewPassword,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };

    // ---------------- RESET_FORGOT_PASSWORD ----------------
    case RESET_SET_UP_NEW_PASSWORD:
      return {
        ...state,
        setUpNewPassword: initialState.setUpNewPassword
      };

    // ---------------- AD_AUTHENTICATE ----------------
    case AD_AUTHENTICATE:
      return {
        ...state,
        adAuthenticate: {
          ...state.adAuthenticate,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };

    case AD_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        adAuthenticate: {
          ...state.adAuthenticate,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };

    case AD_AUTHENTICATE_FAIL:
      return {
        ...state,
        adAuthenticate: {
          ...state.adAuthenticate,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };

    // ---------------- RESET_AD_AUTHENTICATE ----------------
    case RESET_AD_AUTHENTICATE:
      return {
        ...state,
        adAuthenticate: initialState.adAuthenticate
      };
    default:
      return state;
  }
};
