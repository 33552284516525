import EndPoints from "../config/endpoint";
import Api from "./api";

export default class AreaRequestAPI {
  static createAreaAPI = (data: any) =>
    Api.request({
      method: "post",
      url: EndPoints.CREATE_AREA,
      data
    });

  static updateAreaAPI = (data: { updateUserDTO: object; userID: string }) => {
    return Api.request({
      method: "put",
      url: `${EndPoints.UPDATE_AREA}/${data.userID}`,
      data: data?.updateUserDTO
    });
  };

  static getAreaAPI = (data: any) =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_AREA}/${data.id}`,

      data
    });

  static getUserListAPI = (payload) => {
    console.log("🚀 ~ file: master-data.area.api.ts:29 ~ AreaRequestAPI ~ payload:", payload);

    return Api.request({
      method: "get",
      url: `${EndPoints.GET_AREA_LIST}?page=${payload?.page}&region=${payload?.region}&name=${payload?.name}`
    });
  };

  static deleteAreaAPI = (data: any) =>
    Api.request({
      method: "delete",
      url: `${EndPoints.DELETE_AREA}/${data.id}`
    });
}
