const ACTION_HEADER = "LOG/";

export const GET_LOG_LIST = ACTION_HEADER + "GET_LOG_LIST";
export const GET_LOG_LIST_SUCCESS = ACTION_HEADER + "GET_LOG_LIST_SUCCESS";
export const GET_LOG_LIST_FAIL = ACTION_HEADER + "GET_LOG_LIST_FAIL";

export const RESET_GET_LOG_LIST = ACTION_HEADER + "RESET_GET_LOG_LIST";

export const GET_MODEL_LIST = ACTION_HEADER + "GET_MODEL_LIST";
export const GET_MODEL_LIST_SUCCESS = ACTION_HEADER + "GET_MODEL_LIST_SUCCESS";
export const GET_MODEL_LIST_FAIL = ACTION_HEADER + "GET_MODEL_LIST_FAIL";

export const RESET_GET_MODEL_LIST = ACTION_HEADER + "RESET_GET_MODEL_LIST";
