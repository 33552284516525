import { put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_REGION,
  CREATE_REGION_FAIL,
  CREATE_REGION_SUCCESS,
  DELETE_REGION,
  DELETE_REGION_FAIL,
  DELETE_REGION_SUCCESS,
  GET_REGION,
  GET_REGION_DROPDOWN_LIST,
  GET_REGION_DROPDOWN_LIST_FAIL,
  GET_REGION_DROPDOWN_LIST_SUCCESS,
  GET_REGION_FAIL,
  GET_REGION_LIST,
  GET_REGION_LIST_FAIL,
  GET_REGION_LIST_SUCCESS,
  GET_REGION_SUCCESS,
  UPDATE_REGION,
  UPDATE_REGION_FAIL,
  UPDATE_REGION_SUCCESS
} from "./types";
import RegionRequestAPI from "../../../apis/master-data.region.api";

export function* createRegion({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => RegionRequestAPI.createRegionAPI(payload));
    yield put({ type: CREATE_REGION_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: CREATE_REGION_FAIL, payload: error });
    fail(error);
  }
}

export function* getRegion({ payload }: any): any {
  try {
    const data = yield call(() => RegionRequestAPI.getRegionAPI(payload));
    yield put({ type: GET_REGION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_REGION_FAIL, payload: error });
  }
}

export function* getRegionList({ payload }: any): any {
  try {
    const data = yield call(() => RegionRequestAPI.getRegionListAPI(payload));
    yield put({ type: GET_REGION_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_REGION_LIST_FAIL, payload: error });
  }
}

export function* getRegionDropdownList({}: any): any {
  try {
    const data = yield call(() => RegionRequestAPI.getRegionDropdownListAPI());
    yield put({ type: GET_REGION_DROPDOWN_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_REGION_DROPDOWN_LIST_FAIL, payload: error });
  }
}

export function* updateRegion({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => RegionRequestAPI.updateRegionAPI(payload));
    yield put({ type: UPDATE_REGION_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: UPDATE_REGION_FAIL, payload: error });
    fail(error);
  }
}

export function* deleteRegion({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => RegionRequestAPI.deleteRegionAPI(payload));
    yield put({ type: DELETE_REGION_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: DELETE_REGION_FAIL, payload: error });
    fail(error);
  }
}

function* authSaga() {
  yield takeEvery(CREATE_REGION, createRegion);
  yield takeEvery(GET_REGION, getRegion);
  yield takeEvery(GET_REGION_LIST, getRegionList);
  yield takeEvery(GET_REGION_DROPDOWN_LIST, getRegionDropdownList);
  yield takeEvery(UPDATE_REGION, updateRegion);
  yield takeEvery(DELETE_REGION, deleteRegion);
}

export default authSaga;
