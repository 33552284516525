import { put, call, takeEvery } from "redux-saga/effects";
import {
  ASSIGN_PERMISSION,
  ASSIGN_PERMISSION_FAIL,
  ASSIGN_PERMISSION_SUCCESS,
  CREATE_PERMISSION,
  CREATE_PERMISSION_FAIL,
  CREATE_PERMISSION_SUCCESS,
  DELETE_PERMISSION,
  DELETE_PERMISSION_FAIL,
  DELETE_PERMISSION_SUCCESS,
  GET_PERMISSION,
  GET_PERMISSION_FAIL,
  GET_PERMISSION_LIST,
  GET_PERMISSION_LIST_FAIL,
  GET_PERMISSION_LIST_SUCCESS,
  GET_PERMISSION_SUCCESS,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_SUCCESS
} from "./types";
import PermissionRequestAPI from "../../apis/permission.api";
import NotificationManager from "../../components/NotificationManager/NotificationManager";
import history from "../../library/helpers/history";

export function* createPermission({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => PermissionRequestAPI.createPermissionAPI(payload));
    yield put({ type: CREATE_PERMISSION_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: CREATE_PERMISSION_FAIL, payload: error });
    fail(error);
  }
}

export function* getPermission({ payload }: any): any {
  try {
    const data = yield call(() => PermissionRequestAPI.getPermissionAPI(payload));
    yield put({ type: GET_PERMISSION_SUCCESS, payload: data });
    // success(data);
  } catch (error) {
    yield put({ type: GET_PERMISSION_FAIL, payload: error });
    // fail(error);
  }
}

export function* getPermissionList({}: any): any {
  try {
    const data = yield call(() => PermissionRequestAPI.getPermissionListAPI());
    yield put({ type: GET_PERMISSION_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_PERMISSION_LIST_FAIL, payload: error });
  }
}

export function* updatePermission({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => PermissionRequestAPI.updatePermissionAPI(payload));
    yield put({ type: UPDATE_PERMISSION_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: UPDATE_PERMISSION_FAIL, payload: error });
    fail(error);
  }
}

export function* deletePermission({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => PermissionRequestAPI.deletePermissionAPI(payload));
    yield put({ type: DELETE_PERMISSION_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: DELETE_PERMISSION_FAIL, payload: error });
    fail(error);
  }
}

export function* assignPermission({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => PermissionRequestAPI.assignPermissionAPI(payload));
    yield put({ type: ASSIGN_PERMISSION_SUCCESS, payload: data });
    success();
    NotificationManager("success", "Success", "Assign permission successfully..!");
  } catch (error) {
    NotificationManager("error", "Failed", "Assign permission failed..!");

    yield put({ type: ASSIGN_PERMISSION_FAIL, payload: error });
    fail();
  }
}

function* authSaga() {
  yield takeEvery(CREATE_PERMISSION, createPermission);
  yield takeEvery(GET_PERMISSION, getPermission);
  yield takeEvery(GET_PERMISSION_LIST, getPermissionList);
  yield takeEvery(UPDATE_PERMISSION, updatePermission);
  yield takeEvery(DELETE_PERMISSION, deletePermission);
  yield takeEvery(ASSIGN_PERMISSION, assignPermission);
}

export default authSaga;
