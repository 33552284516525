import { initialStateModal } from "../../constant/CommonConstant";
import {
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USER,
  GET_USER_DROPDOWN_LIST,
  GET_USER_DROPDOWN_LIST_FAIL,
  GET_USER_DROPDOWN_LIST_SUCCESS,
  GET_USER_FAIL,
  GET_USER_LIST,
  GET_USER_LIST_BY_BU_AND_LEVEL,
  GET_USER_LIST_BY_BU_AND_LEVEL_FAIL,
  GET_USER_LIST_BY_BU_AND_LEVEL_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_SUCCESS,
  GET_USER_SUCCESS,
  RESET_CREATE_USER,
  RESET_DELETE_USER,
  RESET_GET_USER,
  RESET_GET_USER_DROPDOWN_LIST,
  RESET_GET_USER_LIST,
  RESET_GET_USER_LIST_BY_BU_AND_LEVEL,
  RESET_UPDATE_USER,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS
} from "./types";

const initialState = {
  createUser: {
    ...initialStateModal
  },
  updateUser: {
    ...initialStateModal
  },
  getUser: {
    ...initialStateModal
  },
  getUserList: {
    ...initialStateModal
  },
  getUserDropdownList: {
    ...initialStateModal
  },
  deleteUser: {
    ...initialStateModal
  },
  getUserListByBUAndLevel: {
    ...initialStateModal
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------- CREATE_USER ----------------
    case CREATE_USER:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_CREATE_USER ----------------
    case RESET_CREATE_USER:
      return {
        ...state,
        createUser: initialState.createUser
      };

    // ---------------- UPDATE_USER ----------------
    case UPDATE_USER:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_UPDATE_USER ----------------
    case RESET_UPDATE_USER:
      return {
        ...state,
        updateUser: initialState.updateUser
      };

    // ---------------- GET_USER ----------------
    case GET_USER:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_USER_FAIL:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_USER ----------------
    case RESET_GET_USER:
      return {
        ...state,
        getUser: initialState.getUser
      };

    // ---------------- GET_USER_LIST ----------------
    case GET_USER_LIST:
      return {
        ...state,
        getUserList: {
          ...state.getUserList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        getUserList: {
          ...state.getUserList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_USER_LIST_FAIL:
      return {
        ...state,
        getUserList: {
          ...state.getUserList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_USER_LIST ----------------
    case RESET_GET_USER_LIST:
      return {
        ...state,
        getUserList: initialState.getUserList
      };

    // ---------------- GET_USER_DROPDOWN_LIST ----------------
    case GET_USER_DROPDOWN_LIST:
      return {
        ...state,
        getUserDropdownList: {
          ...state.getUserDropdownList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_USER_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        getUserDropdownList: {
          ...state.getUserDropdownList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_USER_DROPDOWN_LIST_FAIL:
      return {
        ...state,
        getUserDropdownList: {
          ...state.getUserDropdownList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_USER_DROPDOWN_LIST ----------------
    case RESET_GET_USER_DROPDOWN_LIST:
      return {
        ...state,
        getUserDropdownList: initialState.getUserDropdownList
      };

    // ---------------- DELETE_USER ----------------
    case DELETE_USER:
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_DELETE_USER ----------------
    case RESET_DELETE_USER:
      return {
        ...state,
        deleteUser: initialState.deleteUser
      };

    // ---------------- GET_USER_LIST_BY_BU_AND_LEVEL ----------------
    case GET_USER_LIST_BY_BU_AND_LEVEL:
      return {
        ...state,
        getUserListByBUAndLevel: {
          ...state.getUserListByBUAndLevel,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_USER_LIST_BY_BU_AND_LEVEL_SUCCESS:
      return {
        ...state,
        getUserListByBUAndLevel: {
          ...state.getUserListByBUAndLevel,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_USER_LIST_BY_BU_AND_LEVEL_FAIL:
      return {
        ...state,
        getUserListByBUAndLevel: {
          ...state.getUserListByBUAndLevel,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_USER_LIST_BY_BU_AND_LEVEL ----------------
    case RESET_GET_USER_LIST_BY_BU_AND_LEVEL:
      return {
        ...state,
        getUserListByBUAndLevel: initialState.getUserListByBUAndLevel
      };

    default:
      return state;
  }
};
