import { put, call, takeEvery } from "redux-saga/effects";
import AuthRequestAPI from "../../apis/auth.api";
import {
  AD_AUTHENTICATE,
  AD_AUTHENTICATE_FAIL,
  AD_AUTHENTICATE_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SET_UP_NEW_PASSWORD,
  SET_UP_NEW_PASSWORD_FAIL,
  SET_UP_NEW_PASSWORD_SUCCESS
} from "./types";
import { saveState } from "../../library/helpers/localStorage";
import { AFTER_LOGIN_ROUTE } from "../../config/app.config";

export function* login({ payload, success }: any): any {
  try {
    const data = yield call(() => AuthRequestAPI.loginAPI(payload));
    saveState("access_token", data?.data?.access_token);
    window.location.href = AFTER_LOGIN_ROUTE;

    success();
    yield put({ type: LOGIN_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: LOGIN_FAIL, payload: error });
  }
}

export function* forgotPassword({ payload, success, failed }: any): any {
  try {
    const data = yield call(() => AuthRequestAPI.forgotPasswordAPI(payload));
    yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_FAIL, payload: error });
    failed(error);
  }
}

export function* setUpNewPassword({ payload, success, failed }: any): any {
  try {
    const data = yield call(() => AuthRequestAPI.setUpNewPasswordAPI(payload));
    yield put({ type: SET_UP_NEW_PASSWORD_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: SET_UP_NEW_PASSWORD_FAIL, payload: error });
    failed(error);
  }
}

export function* adAuthenticate(): any {
  try {
    const data = yield call(() => AuthRequestAPI.adAuthenticateAPI());
    yield put({ type: AD_AUTHENTICATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: AD_AUTHENTICATE_FAIL, payload: error });
  }
}

function* authSaga() {
  yield takeEvery(LOGIN, login);
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(SET_UP_NEW_PASSWORD, setUpNewPassword);
  yield takeEvery(AD_AUTHENTICATE, adAuthenticate);
}

export default authSaga;
