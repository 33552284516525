const ACTION_HEADER = "MASTER-DATA/BUSINESS-UNIT/";

export const CREATE_BUSINESS_UNIT = ACTION_HEADER + "CREATE_BUSINESS_UNIT";
export const CREATE_BUSINESS_UNIT_SUCCESS = ACTION_HEADER + "CREATE_BUSINESS_UNIT_SUCCESS";
export const CREATE_BUSINESS_UNIT_FAIL = ACTION_HEADER + "CREATE_BUSINESS_UNIT_FAIL";

export const RESET_CREATE_BUSINESS_UNIT = ACTION_HEADER + "RESET_CREATE_BUSINESS_UNIT";

export const GET_BUSINESS_UNIT = ACTION_HEADER + "GET_BUSINESS_UNIT";
export const GET_BUSINESS_UNIT_SUCCESS = ACTION_HEADER + "GET_BUSINESS_UNIT_SUCCESS";
export const GET_BUSINESS_UNIT_FAIL = ACTION_HEADER + "GET_BUSINESS_UNIT_FAIL";

export const RESET_GET_BUSINESS_UNIT = ACTION_HEADER + "RESET_GET_BUSINESS_UNIT";

export const GET_BUSINESS_UNIT_LIST = ACTION_HEADER + "GET_BUSINESS_UNIT_LIST";
export const GET_BUSINESS_UNIT_LIST_SUCCESS = ACTION_HEADER + "GET_BUSINESS_UNIT_LIST_SUCCESS";
export const GET_BUSINESS_UNIT_LIST_FAIL = ACTION_HEADER + "GET_BUSINESS_UNIT_LIST_FAIL";

export const RESET_GET_BUSINESS_UNIT_LIST = ACTION_HEADER + "RESET_GET_BUSINESS_UNIT_LIST";

export const GET_BUSINESS_UNIT_DROPDWON_LIST = ACTION_HEADER + "GET_BUSINESS_UNIT_DROPDWON_LIST";
export const GET_BUSINESS_UNIT_DROPDWON_LIST_SUCCESS =
  ACTION_HEADER + "GET_BUSINESS_UNIT_DROPDWON_LIST_SUCCESS";
export const GET_BUSINESS_UNIT_DROPDWON_LIST_FAIL =
  ACTION_HEADER + "GET_BUSINESS_UNIT_DROPDWON_LIST_FAIL";

export const RESET_GET_BUSINESS_UNIT_DROPDWON_LIST =
  ACTION_HEADER + "RESET_GET_BUSINESS_UNIT_DROPDWON_LIST";

export const UPDATE_BUSINESS_UNIT = ACTION_HEADER + "UPDATE_BUSINESS_UNIT";
export const UPDATE_BUSINESS_UNIT_SUCCESS = ACTION_HEADER + "UPDATE_BUSINESS_UNIT_SUCCESS";
export const UPDATE_BUSINESS_UNIT_FAIL = ACTION_HEADER + "UPDATE_BUSINESS_UNIT_FAIL";

export const RESET_UPDATE_BUSINESS_UNIT = ACTION_HEADER + "RESET_UPDATE_BUSINESS_UNIT";

export const DELETE_BUSINESS_UNIT = ACTION_HEADER + "DELETE_BUSINESS_UNIT";
export const DELETE_BUSINESS_UNIT_SUCCESS = ACTION_HEADER + "DELETE_BUSINESS_UNIT_SUCCESS";
export const DELETE_BUSINESS_UNIT_FAIL = ACTION_HEADER + "DELETE_BUSINESS_UNIT_FAIL";

export const RESET_DELETE_BUSINESS_UNIT = ACTION_HEADER + "RESET_DELETE_BUSINESS_UNIT";
