const ACTION_HEADER = "MASTER-DATA/AREA/";

export const CREATE_AREA = ACTION_HEADER + "CREATE_AREA";
export const CREATE_AREA_SUCCESS = ACTION_HEADER + "CREATE_AREA_SUCCESS";
export const CREATE_AREA_FAIL = ACTION_HEADER + "CREATE_AREA_FAIL";

export const RESET_CREATE_AREA = ACTION_HEADER + "RESET_CREATE_AREA";

export const GET_AREA = ACTION_HEADER + "GET_AREA";
export const GET_AREA_SUCCESS = ACTION_HEADER + "GET_AREA_SUCCESS";
export const GET_AREA_FAIL = ACTION_HEADER + "GET_AREA_FAIL";

export const RESET_GET_AREA = ACTION_HEADER + "RESET_GET_AREA";

export const GET_AREA_LIST = ACTION_HEADER + "GET_AREA_LIST";
export const GET_AREA_LIST_SUCCESS = ACTION_HEADER + "GET_AREA_LIST_SUCCESS";
export const GET_AREA_LIST_FAIL = ACTION_HEADER + "GET_AREA_LIST_FAIL";

export const RESET_GET_AREA_LIST = ACTION_HEADER + "RESET_GET_AREA_LIST";

export const UPDATE_AREA = ACTION_HEADER + "UPDATE_AREA";
export const UPDATE_AREA_SUCCESS = ACTION_HEADER + "UPDATE_AREA_SUCCESS";
export const UPDATE_AREA_FAIL = ACTION_HEADER + "UPDATE_AREA_FAIL";

export const RESET_UPDATE_AREA = ACTION_HEADER + "RESET_UPDATE_AREA";

export const DELETE_AREA = ACTION_HEADER + "DELETE_AREA";
export const DELETE_AREA_SUCCESS = ACTION_HEADER + "DELETE_AREA_SUCCESS";
export const DELETE_AREA_FAIL = ACTION_HEADER + "DELETE_AREA_FAIL";

export const RESET_DELETE_AREA = ACTION_HEADER + "RESET_DELETE_AREA";
