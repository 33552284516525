import { put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_ROLE,
  CREATE_ROLE_FAIL,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_SUCCESS,
  GET_ROLE,
  GET_ROLE_DROPDWON_LIST,
  GET_ROLE_DROPDWON_LIST_FAIL,
  GET_ROLE_DROPDWON_LIST_SUCCESS,
  GET_ROLE_FAIL,
  GET_ROLE_LEVEL,
  GET_ROLE_LEVEL_FAIL,
  GET_ROLE_LEVEL_SUCCESS,
  GET_ROLE_LIST,
  GET_ROLE_LIST_FAIL,
  GET_ROLE_LIST_SUCCESS,
  GET_ROLE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS
} from "./types";
import RoleRequestAPI from "../../apis/role.api";

export function* createRole({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => RoleRequestAPI.createRoleAPI(payload));
    yield put({ type: CREATE_ROLE_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: CREATE_ROLE_FAIL, payload: error });
    fail(error);
  }
}

export function* getRole({ payload }: any): any {
  try {
    const data = yield call(() => RoleRequestAPI.getRoleAPI(payload));
    yield put({ type: GET_ROLE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ROLE_FAIL, payload: error });
  }
}

export function* getRoleList({ payload }: any): any {
  try {
    const data = yield call(() => RoleRequestAPI.getRoleListAPI(payload));
    yield put({ type: GET_ROLE_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ROLE_LIST_FAIL, payload: error });
  }
}

export function* getRoleDropDownList({}: any): any {
  try {
    const data = yield call(() => RoleRequestAPI.getRoleDropDownListAPI());
    yield put({ type: GET_ROLE_DROPDWON_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ROLE_DROPDWON_LIST_FAIL, payload: error });
  }
}

export function* updateRole({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => RoleRequestAPI.updateRoleAPI(payload));
    yield put({ type: UPDATE_ROLE_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: UPDATE_ROLE_FAIL, payload: error });
    fail(error);
  }
}

export function* deleteRole({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => RoleRequestAPI.deleteRoleAPI(payload));
    yield put({ type: DELETE_ROLE_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: DELETE_ROLE_FAIL, payload: error });
    fail(error);
  }
}

export function* getRoleLevel({}: any): any {
  try {
    const data = yield call(() => RoleRequestAPI.getRoleLevelAPI());
    yield put({ type: GET_ROLE_LEVEL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ROLE_LEVEL_FAIL, payload: error });
  }
}

function* authSaga() {
  yield takeEvery(CREATE_ROLE, createRole);
  yield takeEvery(GET_ROLE, getRole);
  yield takeEvery(GET_ROLE_LIST, getRoleList);
  yield takeEvery(GET_ROLE_LEVEL, getRoleLevel);
  yield takeEvery(GET_ROLE_DROPDWON_LIST, getRoleDropDownList);
  yield takeEvery(UPDATE_ROLE, updateRole);
  yield takeEvery(DELETE_ROLE, deleteRole);
}

export default authSaga;
