import Axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../config/app.config";
import NotificationManager from "../components/NotificationManager/NotificationManager";
import { clearAllState } from "../library/helpers/localStorage";

interface IRequest {
  url?: string;
  data?: object;
  params?: object;
  action?: string;
  guest?: boolean;
  isFormData?: boolean;
  hideLoader?: boolean;
  method?: AxiosRequestConfig["method"];
  headers?: AxiosRequestConfig["headers"];
}

const createPath = (action: string) => {
  const url = `${BASE_URL}${action}`;

  return { url };
};

export const getAuthToken = () => {
  try {
    return {
      accessToken: JSON.parse(localStorage.getItem("access_token"))
    };
  } catch (error) {
    return null;
  }
};

interface authType {
  method: any;
  url: String;
  data?: any;
}

const CustomAxios = Axios.create();

CustomAxios.interceptors.request.use(
  (req: any) => {
    const tokens = getAuthToken();
    req.headers = {
      ...req.headers,
      Authorization: "Bearer " + tokens?.accessToken
    };
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

CustomAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const originalReq = error.config;
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      clearAllState();
      window.location.href = "/";
      // const token = getAuthToken();
      // const headers = {
      //   Authorization: `Bearer ${token?.refreshToken}`
      // };
      // return Axios.post(
      //   `${BASE_URL}auth/user/refresh`,
      //   {},
      //   {
      //     headers: headers
      //   }
      // )
      //   .then((response) => {
      //     //   store.dispatch(saveAuthTokens(response.data));
      //     return CustomAxios(originalReq);
      //   })
      //   .catch((err) => {
      //     //   store.dispatch(removeAuthTokens());
      //     console.error(err);
      //   });
    }
    console.error(error);
    return Promise.reject(error);
  }
);

const authRequest = async ({ method, url, data }: authType) => {
  try {
    const res = await Axios({
      method: method,
      url: BASE_URL + url,
      data: data
    });

    return res.data;
  } catch (error) {
    const responseMessage = error?.response?.data?.message;
    NotificationManager("error", "Failed", responseMessage);
    throw error;
  }
};

const request = ({
  url,
  data,
  params,
  action,
  method,
  guest,
  isFormData,
  // guest = false,
  // hideLoader = false,
  headers: addHeaders
}: IRequest) =>
  new Promise((resolve, reject) => {
    // store.dispatch(startLoading());
    const headers = {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",

      ...addHeaders
    };

    const customUrl = createPath(url);
    const axiosFormDataConfig: AxiosRequestConfig = {
      data,
      params,
      headers,
      timeout: 60000,
      method: method || "post",
      transformRequest: (formdata, headers) => {
        return data;
      },
      url: BASE_URL + url
    };

    const axiosConfig: AxiosRequestConfig = {
      data,
      params,
      headers,
      onUploadProgress: (progressEvent) => {
        // const percentCompleted = Math.round(
        //   (progressEvent.loaded * 100) / progressEvent.total
        // );
      },
      timeout: 60000,
      method: method || "post",
      url: customUrl.url
    };

    CustomAxios(isFormData ? axiosFormDataConfig : axiosConfig)
      .then((response) => {
        resolve(response.data);
        // store.dispatch(endLoading());
      })
      .catch((error) => {
        // store.dispatch(endLoading());
        const responseMessage = error?.response?.data?.message;
        NotificationManager("error", "Failed", responseMessage);
        reject(error?.response?.data || error);
      });
  });

export default { request, authRequest };
