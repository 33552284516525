import { put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USER,
  GET_USER_DROPDOWN_LIST,
  GET_USER_DROPDOWN_LIST_FAIL,
  GET_USER_DROPDOWN_LIST_SUCCESS,
  GET_USER_FAIL,
  GET_USER_LIST,
  GET_USER_LIST_BY_BU_AND_LEVEL,
  GET_USER_LIST_BY_BU_AND_LEVEL_FAIL,
  GET_USER_LIST_BY_BU_AND_LEVEL_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_SUCCESS,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS
} from "./types";
import UserRequestAPI from "../../apis/user.api";

export function* createUser({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => UserRequestAPI.createUserAPI(payload));
    yield put({ type: CREATE_USER_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: CREATE_USER_FAIL, payload: error });
    fail(error);
  }
}

export function* updateUser({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => UserRequestAPI.updateUserAPI(payload));
    yield put({ type: UPDATE_USER_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: UPDATE_USER_FAIL, payload: error });
    fail(error);
  }
}

export function* getUser({ payload }: any): any {
  try {
    const data = yield call(() => UserRequestAPI.getUserAPI(payload.userId));
    yield put({ type: GET_USER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_USER_FAIL, payload: error });
  }
}

export function* getUserList({ payload }: any): any {
  try {
    const data = yield call(() => UserRequestAPI.getUserListAPI(payload));
    yield put({ type: GET_USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_USER_LIST_FAIL, payload: error });
  }
}

export function* getUserDropdownList({}: any): any {
  try {
    const data = yield call(() => UserRequestAPI.getUserDropdownListAPI());
    yield put({ type: GET_USER_DROPDOWN_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_USER_DROPDOWN_LIST_FAIL, payload: error });
  }
}

export function* deleteUser({ payload }: any): any {
  try {
    const data = yield call(() => UserRequestAPI.deleteUserAPI(payload));
    yield put({ type: DELETE_USER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: DELETE_USER_FAIL, payload: error });
  }
}

export function* getUserListByBUAndLevel({ payload }: any): any {
  try {
    const data = yield call(() => UserRequestAPI.getUserListByBUAndLevelAPI(payload));
    yield put({ type: GET_USER_LIST_BY_BU_AND_LEVEL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_USER_LIST_BY_BU_AND_LEVEL_FAIL, payload: error });
  }
}

function* authSaga() {
  yield takeEvery(CREATE_USER, createUser);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(GET_USER, getUser);
  yield takeEvery(GET_USER_LIST, getUserList);
  yield takeEvery(GET_USER_DROPDOWN_LIST, getUserDropdownList);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(GET_USER_LIST_BY_BU_AND_LEVEL, getUserListByBUAndLevel);
}

export default authSaga;
