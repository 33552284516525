import { initialStateModal } from "../../constant/CommonConstant";
import {
  ASSIGN_PERMISSION,
  ASSIGN_PERMISSION_FAIL,
  ASSIGN_PERMISSION_SUCCESS,
  CREATE_PERMISSION,
  CREATE_PERMISSION_FAIL,
  CREATE_PERMISSION_SUCCESS,
  DELETE_PERMISSION,
  DELETE_PERMISSION_FAIL,
  DELETE_PERMISSION_SUCCESS,
  GET_PERMISSION,
  GET_PERMISSION_FAIL,
  GET_PERMISSION_LIST,
  GET_PERMISSION_LIST_FAIL,
  GET_PERMISSION_LIST_SUCCESS,
  GET_PERMISSION_SUCCESS,
  RESET_ASSIGN_PERMISSION,
  RESET_CREATE_PERMISSION,
  RESET_DELETE_PERMISSION,
  RESET_GET_PERMISSION,
  RESET_GET_PERMISSION_LIST,
  RESET_UPDATE_PERMISSION,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_SUCCESS
} from "./types";

const initialState = {
  createPermission: {
    ...initialStateModal
  },
  getPermission: {
    ...initialStateModal
  },
  getPermissionList: {
    ...initialStateModal,
    permissionGroup: []
  },
  updatePermission: {
    ...initialStateModal
  },
  deletePermission: {
    ...initialStateModal
  },
  assignPermission: {
    ...initialStateModal
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------- CREATE_PERMISSION ----------------
    case CREATE_PERMISSION:
      return {
        ...state,
        createPermission: {
          ...state.createPermission,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        createPermission: {
          ...state.createPermission,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case CREATE_PERMISSION_FAIL:
      return {
        ...state,
        createPermission: {
          ...state.createPermission,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_CREATE_PERMISSION ----------------
    case RESET_CREATE_PERMISSION:
      return {
        ...state,
        createPermission: initialState.createPermission
      };

    // ---------------- GET_PERMISSION ----------------
    case GET_PERMISSION:
      return {
        ...state,
        getPermission: {
          ...state.getPermission,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        getPermission: {
          ...state.getPermission,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_PERMISSION_FAIL:
      return {
        ...state,
        getPermission: {
          ...state.getPermission,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_PERMISSION ----------------
    case RESET_GET_PERMISSION:
      return {
        ...state,
        getPermission: initialState.getPermission
      };

    // ---------------- GET_PERMISSION_LIST ----------------
    case GET_PERMISSION_LIST:
      return {
        ...state,
        getPermissionList: {
          ...state.getPermissionList,
          loading: true,
          pending: true,
          hasError: false,
          data: [],
          permissionGroup: []
        }
      };
    case GET_PERMISSION_LIST_SUCCESS:
      const permissionGroup = Object.keys(action.payload?.data);
      console.log("🚀 ~ file: reducer.ts:149 ~ permissionGroup:", permissionGroup);

      return {
        ...state,
        getPermissionList: {
          ...state.getPermissionList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload,
          permissionGroup: permissionGroup
        }
      };
    case GET_PERMISSION_LIST_FAIL:
      return {
        ...state,
        getPermissionList: {
          ...state.getPermissionList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: [],
          permissionGroup: []
        }
      };
    // ---------------- RESET_GET_PERMISSION_LIST ----------------
    case RESET_GET_PERMISSION_LIST:
      return {
        ...state,
        getPermissionList: initialState.getPermissionList
      };

    // ---------------- UPDATE_PERMISSION ----------------
    case UPDATE_PERMISSION:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case UPDATE_PERMISSION_FAIL:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_UPDATE_PERMISSION ----------------
    case RESET_UPDATE_PERMISSION:
      return {
        ...state,
        updatePermission: initialState.updatePermission
      };

    // ---------------- DELETE_PERMISSION ----------------
    case DELETE_PERMISSION:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case DELETE_PERMISSION_FAIL:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_DELETE_PERMISSION ----------------
    case RESET_DELETE_PERMISSION:
      return {
        ...state,
        deletePermission: initialState.deletePermission
      };

    // ---------------- ASSIGN_PERMISSION ----------------
    case ASSIGN_PERMISSION:
      return {
        ...state,
        assignPermission: {
          ...state.assignPermission,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case ASSIGN_PERMISSION_SUCCESS:
      return {
        ...state,
        assignPermission: {
          ...state.assignPermission,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case ASSIGN_PERMISSION_FAIL:
      return {
        ...state,
        assignPermission: {
          ...state.assignPermission,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_ASSIGN_PERMISSION ----------------
    case RESET_ASSIGN_PERMISSION:
      return {
        ...state,
        assignPermission: initialState.assignPermission
      };

    default:
      return state;
  }
};
