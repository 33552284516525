const ACTION_HEADER = "MASTER-DATA/PERMISSION/";

export const CREATE_PERMISSION = ACTION_HEADER + "CREATE_PERMISSION";
export const CREATE_PERMISSION_SUCCESS = ACTION_HEADER + "CREATE_PERMISSION_SUCCESS";
export const CREATE_PERMISSION_FAIL = ACTION_HEADER + "CREATE_PERMISSION_FAIL";

export const RESET_CREATE_PERMISSION = ACTION_HEADER + "RESET_CREATE_PERMISSION";

export const GET_PERMISSION = ACTION_HEADER + "GET_PERMISSION";
export const GET_PERMISSION_SUCCESS = ACTION_HEADER + "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAIL = ACTION_HEADER + "GET_PERMISSION_FAIL";

export const RESET_GET_PERMISSION = ACTION_HEADER + "RESET_GET_PERMISSION";

export const GET_PERMISSION_LIST = ACTION_HEADER + "GET_PERMISSION_LIST";
export const GET_PERMISSION_LIST_SUCCESS = ACTION_HEADER + "GET_PERMISSION_LIST_SUCCESS";
export const GET_PERMISSION_LIST_FAIL = ACTION_HEADER + "GET_PERMISSION_LIST_FAIL";

export const RESET_GET_PERMISSION_LIST = ACTION_HEADER + "RESET_GET_PERMISSION_LIST";

export const UPDATE_PERMISSION = ACTION_HEADER + "UPDATE_PERMISSION";
export const UPDATE_PERMISSION_SUCCESS = ACTION_HEADER + "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAIL = ACTION_HEADER + "UPDATE_PERMISSION_FAIL";

export const RESET_UPDATE_PERMISSION = ACTION_HEADER + "RESET_UPDATE_PERMISSION";

export const DELETE_PERMISSION = ACTION_HEADER + "DELETE_PERMISSION";
export const DELETE_PERMISSION_SUCCESS = ACTION_HEADER + "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAIL = ACTION_HEADER + "DELETE_PERMISSION_FAIL";

export const RESET_DELETE_PERMISSION = ACTION_HEADER + "RESET_DELETE_PERMISSION";

export const ASSIGN_PERMISSION = ACTION_HEADER + "ASSIGN_PERMISSION";
export const ASSIGN_PERMISSION_SUCCESS = ACTION_HEADER + "ASSIGN_PERMISSION_SUCCESS";
export const ASSIGN_PERMISSION_FAIL = ACTION_HEADER + "ASSIGN_PERMISSION_FAIL";

export const RESET_ASSIGN_PERMISSION = ACTION_HEADER + "RESET_ASSIGN_PERMISSION";
