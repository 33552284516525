const ThemeInsee = {
  primary: "#FB1018",
  primaryDark: "#bf0a10",
  white: "#ffffff",
  darkRed: "#a51419",
  red: "#FF0000",

  sidebarTextColor: "#ffffff"
};

export { ThemeInsee };
