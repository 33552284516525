import Api from "./api";
import { decode } from "../config/jwt";
import EndPoints from "../config/endpoint";

export default class AuthRequestAPI {
  static loginAPI = (data: any) =>
    Api.authRequest({
      method: "post",
      url: "login",
      data
    });

  static me(accessToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token - commented this for future use
        const user = decode(accessToken) as any;

        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        resolve({
          id: user.sub,
          iat: user.iat,
          email: user.email,
          exp: user.exp,
          role: user.role
        });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  static forgotPasswordAPI = (data: any) =>
    Api.request({
      method: "post",
      action: EndPoints.FORGOT_PASSWORD,
      data
    });

  static setUpNewPasswordAPI = (data: any) =>
    Api.request({
      method: "post",
      action: EndPoints.UPDATE_PASSWORD,
      data
    });

  static adAuthenticateAPI = () =>
    Api.request({
      method: "post",
      url: "refresh_token"
    });
}
