import EndPoints from "../config/endpoint";
import Api from "./api";

export default class RoleRequestAPI {
  static createRoleAPI = (data: any) =>
    Api.request({
      method: "post",
      url: EndPoints.CREATE_ROLE,
      data
    });

  static updateRoleAPI = (data: { roleDTO: object; roleID: string }) => {
    return Api.request({
      method: "put",
      url: `${EndPoints.UPDATE_ROLE}/${data.roleID}`,
      data: data?.roleDTO
    });
  };

  static getRoleAPI = (data: any) =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_ROLE}/${data.id}`,
      data
    });

  static getRoleListAPI = (data: any) => {
    const URL = data?.bu
      ? `${EndPoints.GET_ROLE_LIST}?business_unit=${data?.bu}&page=${data?.page}&name=${data?.name}`
      : `${EndPoints.GET_ROLE_LIST}?page=${data?.page}&name=${data?.name}`;
    return Api.request({
      method: "get",
      url: URL
    });
  };

  static getRoleDropDownListAPI = () =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_ROLE_LIST}?res=list`
    });

  static deleteRoleAPI = (data: any) => {
    console.log("🚀 ~ file: role.api.ts:44 ~ RoleRequestAPI ~ data:", data);
    return Api.request({
      method: "delete",
      url: `${EndPoints.DELETE_ROLE}/${data}`
    });
  };

  static getRoleLevelAPI = () =>
    Api.request({
      method: "get",
      url: `${EndPoints.ROLE_LEVEL}`
    });
}
