import { put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_AREA,
  CREATE_AREA_FAIL,
  CREATE_AREA_SUCCESS,
  DELETE_AREA,
  DELETE_AREA_FAIL,
  DELETE_AREA_SUCCESS,
  GET_AREA,
  GET_AREA_FAIL,
  GET_AREA_LIST,
  GET_AREA_LIST_FAIL,
  GET_AREA_LIST_SUCCESS,
  GET_AREA_SUCCESS,
  UPDATE_AREA,
  UPDATE_AREA_FAIL,
  UPDATE_AREA_SUCCESS
} from "./types";
import AreaRequestAPI from "../../../apis/master-data.area.api";

export function* createArea({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => AreaRequestAPI.createAreaAPI(payload));
    yield put({ type: CREATE_AREA_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: CREATE_AREA_FAIL, payload: error });
    fail(error);
  }
}

export function* getArea({ payload }: any): any {
  try {
    const data = yield call(() => AreaRequestAPI.getAreaAPI(payload));
    yield put({ type: GET_AREA_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_AREA_FAIL, payload: error });
  }
}

export function* getAreaList({ payload }: any): any {
  try {
    const data = yield call(() => AreaRequestAPI.getUserListAPI(payload));
    yield put({ type: GET_AREA_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_AREA_LIST_FAIL, payload: error });
  }
}

export function* updateArea({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => AreaRequestAPI.updateAreaAPI(payload));
    yield put({ type: UPDATE_AREA_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: UPDATE_AREA_FAIL, payload: error });
    fail(error);
  }
}

export function* deleteArea({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => AreaRequestAPI.deleteAreaAPI(payload));
    yield put({ type: DELETE_AREA_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: DELETE_AREA_FAIL, payload: error });
    fail(error);
  }
}

function* authSaga() {
  yield takeEvery(CREATE_AREA, createArea);
  yield takeEvery(GET_AREA, getArea);
  yield takeEvery(GET_AREA_LIST, getAreaList);
  yield takeEvery(UPDATE_AREA, updateArea);
  yield takeEvery(DELETE_AREA, deleteArea);
}

export default authSaga;
