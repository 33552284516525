export const SAVE_AUTH_TOKENS = "SAVE_AUTH_TOKENS";
export const REMOVE_AUTH_TOKENS = "REMOVE_AUTH_TOKENS";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_FORGOT_PASSWORD = "RESET_FORGOT_PASSWORD";

export const SET_UP_NEW_PASSWORD = "SET_UP_NEW_PASSWORD";
export const SET_UP_NEW_PASSWORD_SUCCESS = "SET_UP_NEW_PASSWORD_SUCCESS";
export const SET_UP_NEW_PASSWORD_FAIL = "SET_UP_NEW_PASSWORD_FAIL";

export const RESET_SET_UP_NEW_PASSWORD = "RESET_SET_UP_NEW_PASSWORD";

export const AD_AUTHENTICATE = "AD_AUTHENTICATE";
export const AD_AUTHENTICATE_SUCCESS = "AD_AUTHENTICATE_SUCCESS";
export const AD_AUTHENTICATE_FAIL = "AD_AUTHENTICATE_FAIL";

export const RESET_AD_AUTHENTICATE = "RESET_AD_AUTHENTICATE";
