const ACTION_HEADER = "USER/";

export const CREATE_USER = ACTION_HEADER + "CREATE_USER";
export const CREATE_USER_SUCCESS = ACTION_HEADER + "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = ACTION_HEADER + "CREATE_USER_FAIL";

export const RESET_CREATE_USER = ACTION_HEADER + "RESET_CREATE_USER";

export const UPDATE_USER = ACTION_HEADER + "UPDATE_USER";
export const UPDATE_USER_SUCCESS = ACTION_HEADER + "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = ACTION_HEADER + "UPDATE_USER_FAIL";

export const RESET_UPDATE_USER = ACTION_HEADER + "RESET_UPDATE_USER";

export const GET_USER = ACTION_HEADER + "GET_USER";
export const GET_USER_SUCCESS = ACTION_HEADER + "GET_USER_SUCCESS";
export const GET_USER_FAIL = ACTION_HEADER + "GET_USER_FAIL";

export const RESET_GET_USER = ACTION_HEADER + "RESET_GET_USER";

export const GET_USER_LIST = ACTION_HEADER + "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = ACTION_HEADER + "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAIL = ACTION_HEADER + "GET_USER_LIST_FAIL";

export const RESET_GET_USER_LIST = ACTION_HEADER + "RESET_GET_USER_LIST";

export const GET_USER_DROPDOWN_LIST = ACTION_HEADER + "GET_USER_DROPDOWN_LIST";
export const GET_USER_DROPDOWN_LIST_SUCCESS = ACTION_HEADER + "GET_USER_DROPDOWN_LIST_SUCCESS";
export const GET_USER_DROPDOWN_LIST_FAIL = ACTION_HEADER + "GET_USER_DROPDOWN_LIST_FAIL";

export const RESET_GET_USER_DROPDOWN_LIST = ACTION_HEADER + "RESET_GET_USER_DROPDOWN_LIST";

export const DELETE_USER = ACTION_HEADER + "DELETE_USER";
export const DELETE_USER_SUCCESS = ACTION_HEADER + "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = ACTION_HEADER + "DELETE_USER_FAIL";

export const RESET_DELETE_USER = ACTION_HEADER + "RESET_DELETE_USER";

export const GET_USER_LIST_BY_BU_AND_LEVEL = ACTION_HEADER + "GET_USER_LIST_BY_BU_AND_LEVEL";
export const GET_USER_LIST_BY_BU_AND_LEVEL_SUCCESS =
  ACTION_HEADER + "GET_USER_LIST_BY_BU_AND_LEVEL_SUCCESS";
export const GET_USER_LIST_BY_BU_AND_LEVEL_FAIL =
  ACTION_HEADER + "GET_USER_LIST_BY_BU_AND_LEVEL_FAIL";

export const RESET_GET_USER_LIST_BY_BU_AND_LEVEL =
  ACTION_HEADER + "RESET_GET_USER_LIST_BY_BU_AND_LEVEL";
