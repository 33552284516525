const EndPoints: any = {
  //Auth
  GET_ALL_USERS: "auth/user/list",
  CREATE_USERS: "auth/user/create",
  CHANGE_PASSWORD: "auth/user/change-password",
  EDIT_USER: "auth/user",
  GET_USER_BY_ID: "auth/user",
  REFRESH_TOKEN: "refresh_token",

  GET_LOCATION_BY_ID: "v1/service-providers",
  GET_ALL_LOCATIONS: "v1/service-providers",
  FETCH_NEW_LOCATIONS: "service-provider-location/fetch-new-locations",
  GET_ALL_LOCATIONS_FOR_DROPDOWN: "service-provider-location/locations/dropdown/list",
  GET_ALL_TEAM_MEMBERS: "v1/auth/user/list",
  GET_TEAM_MEMBER_BY_ID: "v1/auth/user",
  CREATE_TEAM_MEMBER: "v1/auth/user/create",
  EDIT_TEAM_MEMBER: "v1/auth/user",
  TEAM_MEMBER_CHANGE_PASSWORD: "auth/user/change-user-password",
  GET_ALL_USER_OPTIONS: "service-provider-auth/user/sp-users",
  FORGOT_PASSWORD: "auth/reset-password",
  UPDATE_PASSWORD: "auth/update-password",
  RESET_TEAM_MEMBER_PASSWORD: "service-provider-auth/request-to-reset-password",
  SEND_INVITATION: "service-provider-auth/sp-send-invitation",
  GET_USERS_BY_LOCATION: "service-provider-auth/user/list-by-location-id",
  ADD_USER_TO_LOCATION: "service-provider-auth/user/create-from-admin",
  GET_ALL_LOCATIONS_FOR_SP: "service-provider-location/locations/dropdown/admin/list",
  ADD_NOTE: "note",
  GET_FILTER_NOTES: "note/filter",
  GET_LOCATION_DETAILS_URI: "service-providers/cqc-location-info",

  // ROLE
  GET_ROLE: "role",
  GET_ROLE_LIST: "role",
  CREATE_ROLE: "role",
  UPDATE_ROLE: "role",
  DELETE_ROLE: "role",
  ROLE_LEVEL: "role-levels",

  // Business Unit
  GET_BUSINESS_UNIT: "business_unit",
  GET_BUSINESS_UNIT_LIST: "business_unit",
  CREATE_BUSINESS_UNIT: "business_unit",
  UPDATE_BUSINESS_UNIT: "business_unit",
  DELETE_BUSINESS_UNIT: "business_unit",

  // REGION
  GET_REGION: "region",
  GET_REGION_LIST: "region",
  CREATE_REGION: "region",
  UPDATE_REGION: "region",
  DELETE_REGION: "region",

  // AREA
  GET_AREA: "area",
  GET_AREA_LIST: "area",
  CREATE_AREA: "area",
  UPDATE_AREA: "area",
  DELETE_AREA: "area",

  // Permission
  GET_PERMISSION: "permission",
  GET_PERMISSION_LIST: "permission",
  CREATE_PERMISSION: "permission",
  UPDATE_PERMISSION: "permission",
  DELETE_PERMISSION: "permission",
  ASSIGN_PERMISSION: "role-permission",

  // Microsoft AD
  MICROSOFT_AD_LINK: "https://sfaapiqas.insee.lk/saml2/3e13b3ad-3550-4faf-af7d-4629789e6bdb/login"
};

export default EndPoints;
