import { initialStateModal } from "../../constant/CommonConstant";
import {
  GET_LOG_LIST,
  GET_LOG_LIST_FAIL,
  GET_LOG_LIST_SUCCESS,
  GET_MODEL_LIST,
  GET_MODEL_LIST_FAIL,
  GET_MODEL_LIST_SUCCESS,
  RESET_GET_LOG_LIST,
  RESET_GET_MODEL_LIST
} from "./types";

const initialState = {
  logList: {
    ...initialStateModal
  },
  modelList: {
    ...initialStateModal
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------- GET_LOG_LIST ----------------
    case GET_LOG_LIST:
      return {
        ...state,
        logList: {
          ...state.logList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_LOG_LIST_SUCCESS:
      return {
        ...state,
        logList: {
          ...state.logList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_LOG_LIST_FAIL:
      return {
        ...state,
        logList: {
          ...state.logList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_LOG_LIST ----------------
    case RESET_GET_LOG_LIST:
      return {
        ...state,
        logList: initialState.logList
      };

    // ---------------- GET_MODEL_LIST ----------------
    case GET_MODEL_LIST:
      return {
        ...state,
        modelList: {
          ...state.modelList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_MODEL_LIST_SUCCESS:
      return {
        ...state,
        modelList: {
          ...state.modelList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_MODEL_LIST_FAIL:
      return {
        ...state,
        modelList: {
          ...state.modelList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_MODEL_LIST ----------------
    case RESET_GET_MODEL_LIST:
      return {
        ...state,
        modelList: initialState.modelList
      };

    default:
      return state;
  }
};
