//basic state
interface IInitialStateModal {
  loading: boolean;
  pending: boolean;
  hasError: boolean;
  data: any;
  error: any;
}

export const initialStateModal: IInitialStateModal = {
  loading: true,
  pending: false,
  hasError: false,
  data: [],
  error: {}
};

export enum UserRole {
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
  MANAGER = "MANAGER",
  SUPPORT_TEAM = "SUPPORT_TEAM",
  ONBOARDING_TEAM = "ONBOARDING_TEAM",
  SERVICE_PROVIDER = "SERVICE_PROVIDER"
}

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:MM:SS";

export enum USER_MATRIX {
  REGION_USER = "level-4"
}
