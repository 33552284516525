import Api from "./api";

export default class LogRequestAPI {
  static getLogListAPI = (payload) => {
    const URL = Boolean(payload?.model)
      ? `audit_log?page=${payload?.page}&model=${payload?.model}`
      : `audit_log?page=${payload?.page}`;
    return Api.request({
      method: "get",
      url: URL
    });
  };

  static getModelListAPI = () =>
    Api.request({
      method: "get",
      url: `audit_models`
    });
}
