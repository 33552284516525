import EndPoints from "../config/endpoint";
import Api from "./api";

export default class BusinessUnitRequestAPI {
  static createBusinessUnitAPI = (data: any) =>
    Api.request({
      method: "post",
      url: EndPoints.CREATE_BUSINESS_UNIT,
      data
    });

  static updateBusinessUnitAPI = (data: { businessUnitDTO: object; businessUnitID: string }) => {
    return Api.request({
      method: "put",
      url: `${EndPoints.UPDATE_BUSINESS_UNIT}/${data.businessUnitID}`,
      data: data?.businessUnitDTO
    });
  };

  static getBusinessUnitAPI = (data: any) =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_BUSINESS_UNIT}/${data.id}`,

      data
    });

  static getBusinessUnitListAPI = () =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_BUSINESS_UNIT_LIST}`
    });

  static getBusinessUnitDropdownListAPI = () =>
    Api.request({
      method: "get",
      url: `${EndPoints.GET_BUSINESS_UNIT_LIST}?res=list`
    });

  static deleteBusinessUnitAPI = (data: any) => {
    return Api.request({
      method: "delete",
      url: `${EndPoints.DELETE_BUSINESS_UNIT}/${data}`
    });
  };
}
