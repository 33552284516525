import { initialStateModal } from "../../constant/CommonConstant";
import {
  CREATE_BUSINESS_UNIT,
  CREATE_BUSINESS_UNIT_FAIL,
  CREATE_BUSINESS_UNIT_SUCCESS,
  DELETE_BUSINESS_UNIT,
  DELETE_BUSINESS_UNIT_FAIL,
  DELETE_BUSINESS_UNIT_SUCCESS,
  GET_BUSINESS_UNIT,
  GET_BUSINESS_UNIT_DROPDWON_LIST,
  GET_BUSINESS_UNIT_DROPDWON_LIST_FAIL,
  GET_BUSINESS_UNIT_DROPDWON_LIST_SUCCESS,
  GET_BUSINESS_UNIT_FAIL,
  GET_BUSINESS_UNIT_LIST,
  GET_BUSINESS_UNIT_LIST_FAIL,
  GET_BUSINESS_UNIT_LIST_SUCCESS,
  GET_BUSINESS_UNIT_SUCCESS,
  RESET_CREATE_BUSINESS_UNIT,
  RESET_DELETE_BUSINESS_UNIT,
  RESET_GET_BUSINESS_UNIT,
  RESET_GET_BUSINESS_UNIT_DROPDWON_LIST,
  RESET_GET_BUSINESS_UNIT_LIST,
  RESET_UPDATE_BUSINESS_UNIT,
  UPDATE_BUSINESS_UNIT,
  UPDATE_BUSINESS_UNIT_FAIL,
  UPDATE_BUSINESS_UNIT_SUCCESS
} from "./types";

const initialState = {
  createBusinessUnit: {
    ...initialStateModal
  },
  getBusinessUnit: {
    ...initialStateModal
  },
  getBusinessUnitList: {
    ...initialStateModal
  },
  getBusinessUnitDropdownList: {
    ...initialStateModal
  },
  updateBusinessUnit: {
    ...initialStateModal
  },
  deleteBusinessUnit: {
    ...initialStateModal
  },
  assignPermission: {
    ...initialStateModal
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------- CREATE_BUSINESS_UNIT ----------------
    case CREATE_BUSINESS_UNIT:
      return {
        ...state,
        createBusinessUnit: {
          ...state.createBusinessUnit,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case CREATE_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        createBusinessUnit: {
          ...state.createBusinessUnit,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case CREATE_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        createBusinessUnit: {
          ...state.createBusinessUnit,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_CREATE_BUSINESS_UNIT ----------------
    case RESET_CREATE_BUSINESS_UNIT:
      return {
        ...state,
        createBusinessUnit: initialState.createBusinessUnit
      };

    // ---------------- GET_BUSINESS_UNIT ----------------
    case GET_BUSINESS_UNIT:
      return {
        ...state,
        getBusinessUnit: {
          ...state.getBusinessUnit,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        getBusinessUnit: {
          ...state.getBusinessUnit,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        getBusinessUnit: {
          ...state.getBusinessUnit,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_BUSINESS_UNIT ----------------
    case RESET_GET_BUSINESS_UNIT:
      return {
        ...state,
        getBusinessUnit: initialState.getBusinessUnit
      };

    // ---------------- GET_BUSINESS_UNIT_LIST ----------------
    case GET_BUSINESS_UNIT_LIST:
      return {
        ...state,
        getBusinessUnitList: {
          ...state.getBusinessUnitList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_BUSINESS_UNIT_LIST_SUCCESS:
      return {
        ...state,
        getBusinessUnitList: {
          ...state.getBusinessUnitList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_BUSINESS_UNIT_LIST_FAIL:
      return {
        ...state,
        getBusinessUnitList: {
          ...state.getBusinessUnitList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_BUSINESS_UNIT_LIST ----------------
    case RESET_GET_BUSINESS_UNIT_LIST:
      return {
        ...state,
        getBusinessUnitList: initialState.getBusinessUnitList
      };

    // ---------------- GET_BUSINESS_UNIT_DROPDWON_LIST ----------------
    case GET_BUSINESS_UNIT_DROPDWON_LIST:
      return {
        ...state,
        getBusinessUnitDropdownList: {
          ...state.getBusinessUnitDropdownList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_BUSINESS_UNIT_DROPDWON_LIST_SUCCESS:
      return {
        ...state,
        getBusinessUnitDropdownList: {
          ...state.getBusinessUnitDropdownList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_BUSINESS_UNIT_DROPDWON_LIST_FAIL:
      return {
        ...state,
        getBusinessUnitDropdownList: {
          ...state.getBusinessUnitDropdownList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_BUSINESS_UNIT_DROPDWON_LIST ----------------
    case RESET_GET_BUSINESS_UNIT_DROPDWON_LIST:
      return {
        ...state,
        getBusinessUnitDropdownList: initialState.getBusinessUnitDropdownList
      };

    // ---------------- UPDATE_BUSINESS_UNIT ----------------
    case UPDATE_BUSINESS_UNIT:
      return {
        ...state,
        updateBusinessUnit: {
          ...state.updateBusinessUnit,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case UPDATE_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        updateBusinessUnit: {
          ...state.updateBusinessUnit,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case UPDATE_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        updateBusinessUnit: {
          ...state.updateBusinessUnit,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_UPDATE_BUSINESS_UNIT ----------------
    case RESET_UPDATE_BUSINESS_UNIT:
      return {
        ...state,
        updateBusinessUnit: initialState.updateBusinessUnit
      };

    // ---------------- DELETE_BUSINESS_UNIT ----------------
    case DELETE_BUSINESS_UNIT:
      return {
        ...state,
        deleteBusinessUnit: {
          ...state.deleteBusinessUnit,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case DELETE_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        deleteBusinessUnit: {
          ...state.deleteBusinessUnit,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case DELETE_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        deleteBusinessUnit: {
          ...state.deleteBusinessUnit,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_DELETE_BUSINESS_UNIT ----------------
    case RESET_DELETE_BUSINESS_UNIT:
      return {
        ...state,
        deleteBusinessUnit: initialState.deleteBusinessUnit
      };

    default:
      return state;
  }
};
