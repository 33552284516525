const ACTION_HEADER = "MASTER-DATA/REGION/";

export const CREATE_REGION = ACTION_HEADER + "CREATE_REGION";
export const CREATE_REGION_SUCCESS = ACTION_HEADER + "CREATE_REGION_SUCCESS";
export const CREATE_REGION_FAIL = ACTION_HEADER + "CREATE_REGION_FAIL";

export const RESET_CREATE_REGION = ACTION_HEADER + "RESET_CREATE_REGION";

export const GET_REGION = ACTION_HEADER + "GET_REGION";
export const GET_REGION_SUCCESS = ACTION_HEADER + "GET_REGION_SUCCESS";
export const GET_REGION_FAIL = ACTION_HEADER + "GET_REGION_FAIL";

export const RESET_GET_REGION = ACTION_HEADER + "RESET_GET_REGION";

export const GET_REGION_LIST = ACTION_HEADER + "GET_REGION_LIST";
export const GET_REGION_LIST_SUCCESS = ACTION_HEADER + "GET_REGION_LIST_SUCCESS";
export const GET_REGION_LIST_FAIL = ACTION_HEADER + "GET_REGION_LIST_FAIL";

export const RESET_GET_REGION_LIST = ACTION_HEADER + "RESET_GET_REGION_LIST";

export const GET_REGION_DROPDOWN_LIST = ACTION_HEADER + "GET_REGION_DROPDOWN_LIST";
export const GET_REGION_DROPDOWN_LIST_SUCCESS = ACTION_HEADER + "GET_REGION_DROPDOWN_LIST_SUCCESS";
export const GET_REGION_DROPDOWN_LIST_FAIL = ACTION_HEADER + "GET_REGION_DROPDOWN_LIST_FAIL";

export const RESET_GET_REGION_DROPDOWN_LIST = ACTION_HEADER + "RESET_GET_REGION_DROPDOWN_LIST";

export const UPDATE_REGION = ACTION_HEADER + "UPDATE_REGION";
export const UPDATE_REGION_SUCCESS = ACTION_HEADER + "UPDATE_REGION_SUCCESS";
export const UPDATE_REGION_FAIL = ACTION_HEADER + "UPDATE_REGION_FAIL";

export const RESET_UPDATE_REGION = ACTION_HEADER + "RESET_UPDATE_REGION";

export const DELETE_REGION = ACTION_HEADER + "DELETE_REGION";
export const DELETE_REGION_SUCCESS = ACTION_HEADER + "DELETE_REGION_SUCCESS";
export const DELETE_REGION_FAIL = ACTION_HEADER + "DELETE_REGION_FAIL";

export const RESET_DELETE_REGION = ACTION_HEADER + "RESET_DELETE_REGION";
