import { put, call, takeEvery } from "redux-saga/effects";
import {
  GET_LOG_LIST,
  GET_LOG_LIST_FAIL,
  GET_LOG_LIST_SUCCESS,
  GET_MODEL_LIST,
  GET_MODEL_LIST_FAIL,
  GET_MODEL_LIST_SUCCESS
} from "./types";
import LogRequestAPI from "../../apis/log.api";

export function* getLogList({ payload }: any): any {
  try {
    const data = yield call(() => LogRequestAPI.getLogListAPI(payload));
    yield put({ type: GET_LOG_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_LOG_LIST_FAIL, payload: error });
  }
}

export function* getModelList({ payload }: any): any {
  try {
    const data = yield call(() => LogRequestAPI.getModelListAPI());
    yield put({ type: GET_MODEL_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_MODEL_LIST_FAIL, payload: error });
  }
}

function* authSaga() {
  yield takeEvery(GET_LOG_LIST, getLogList);
  yield takeEvery(GET_MODEL_LIST, getModelList);
}

export default authSaga;
