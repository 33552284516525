import { put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_BUSINESS_UNIT,
  CREATE_BUSINESS_UNIT_FAIL,
  CREATE_BUSINESS_UNIT_SUCCESS,
  DELETE_BUSINESS_UNIT,
  DELETE_BUSINESS_UNIT_FAIL,
  DELETE_BUSINESS_UNIT_SUCCESS,
  GET_BUSINESS_UNIT,
  GET_BUSINESS_UNIT_DROPDWON_LIST,
  GET_BUSINESS_UNIT_DROPDWON_LIST_FAIL,
  GET_BUSINESS_UNIT_DROPDWON_LIST_SUCCESS,
  GET_BUSINESS_UNIT_FAIL,
  GET_BUSINESS_UNIT_LIST,
  GET_BUSINESS_UNIT_LIST_FAIL,
  GET_BUSINESS_UNIT_LIST_SUCCESS,
  GET_BUSINESS_UNIT_SUCCESS,
  UPDATE_BUSINESS_UNIT,
  UPDATE_BUSINESS_UNIT_FAIL,
  UPDATE_BUSINESS_UNIT_SUCCESS
} from "./types";
import BusinessUnitRequestAPI from "../../apis/business-unit.api";

export function* createBusinessUnit({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => BusinessUnitRequestAPI.createBusinessUnitAPI(payload));
    yield put({ type: CREATE_BUSINESS_UNIT_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: CREATE_BUSINESS_UNIT_FAIL, payload: error });
    fail(error);
  }
}

export function* getBusinessUnit({ payload }: any): any {
  try {
    const data = yield call(() => BusinessUnitRequestAPI.getBusinessUnitAPI(payload));
    yield put({ type: GET_BUSINESS_UNIT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_BUSINESS_UNIT_FAIL, payload: error });
  }
}

export function* getBusinessUnitList(): any {
  try {
    const data = yield call(() => BusinessUnitRequestAPI.getBusinessUnitListAPI());
    yield put({ type: GET_BUSINESS_UNIT_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_BUSINESS_UNIT_LIST_FAIL, payload: error });
  }
}

export function* getBusinessUnitDropdownList(): any {
  try {
    const data = yield call(() => BusinessUnitRequestAPI.getBusinessUnitDropdownListAPI());
    yield put({ type: GET_BUSINESS_UNIT_DROPDWON_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_BUSINESS_UNIT_DROPDWON_LIST_FAIL, payload: error });
  }
}

export function* updateBusinessUnit({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => BusinessUnitRequestAPI.updateBusinessUnitAPI(payload));
    yield put({ type: UPDATE_BUSINESS_UNIT_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: UPDATE_BUSINESS_UNIT_FAIL, payload: error });
    fail(error);
  }
}

export function* deleteBusinessUnit({ payload, success, fail }: any): any {
  try {
    const data = yield call(() => BusinessUnitRequestAPI.deleteBusinessUnitAPI(payload));
    yield put({ type: DELETE_BUSINESS_UNIT_SUCCESS, payload: data });
    success(data);
  } catch (error) {
    yield put({ type: DELETE_BUSINESS_UNIT_FAIL, payload: error });
    fail(error);
  }
}

function* authSaga() {
  yield takeEvery(CREATE_BUSINESS_UNIT, createBusinessUnit);
  yield takeEvery(GET_BUSINESS_UNIT, getBusinessUnit);
  yield takeEvery(GET_BUSINESS_UNIT_LIST, getBusinessUnitList);
  yield takeEvery(GET_BUSINESS_UNIT_DROPDWON_LIST, getBusinessUnitDropdownList);
  yield takeEvery(UPDATE_BUSINESS_UNIT, updateBusinessUnit);
  yield takeEvery(DELETE_BUSINESS_UNIT, deleteBusinessUnit);
}

export default authSaga;
