import { initialStateModal } from "../../../constant/CommonConstant";
import {
  CREATE_AREA,
  CREATE_AREA_FAIL,
  CREATE_AREA_SUCCESS,
  DELETE_AREA,
  DELETE_AREA_FAIL,
  DELETE_AREA_SUCCESS,
  GET_AREA,
  GET_AREA_FAIL,
  GET_AREA_LIST,
  GET_AREA_LIST_FAIL,
  GET_AREA_LIST_SUCCESS,
  GET_AREA_SUCCESS,
  RESET_CREATE_AREA,
  RESET_DELETE_AREA,
  RESET_GET_AREA,
  RESET_GET_AREA_LIST,
  RESET_UPDATE_AREA,
  UPDATE_AREA,
  UPDATE_AREA_FAIL,
  UPDATE_AREA_SUCCESS
} from "./types";

const initialState = {
  createArea: {
    ...initialStateModal
  },
  getArea: {
    ...initialStateModal
  },
  getAreaList: {
    ...initialStateModal
  },
  updateArea: {
    ...initialStateModal
  },
  deleteArea: {
    ...initialStateModal
  },
  assignPermission: {
    ...initialStateModal
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------- CREATE_AREA ----------------
    case CREATE_AREA:
      return {
        ...state,
        createArea: {
          ...state.createArea,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case CREATE_AREA_SUCCESS:
      return {
        ...state,
        createArea: {
          ...state.createArea,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case CREATE_AREA_FAIL:
      return {
        ...state,
        createArea: {
          ...state.createArea,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_CREATE_AREA ----------------
    case RESET_CREATE_AREA:
      return {
        ...state,
        createArea: initialState.createArea
      };

    // ---------------- GET_AREA ----------------
    case GET_AREA:
      return {
        ...state,
        getArea: {
          ...state.getArea,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_AREA_SUCCESS:
      return {
        ...state,
        getArea: {
          ...state.getArea,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_AREA_FAIL:
      return {
        ...state,
        getArea: {
          ...state.getArea,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_AREA ----------------
    case RESET_GET_AREA:
      return {
        ...state,
        getArea: initialState.getArea
      };

    // ---------------- GET_AREA_LIST ----------------
    case GET_AREA_LIST:
      return {
        ...state,
        getAreaList: {
          ...state.getAreaList,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case GET_AREA_LIST_SUCCESS:
      return {
        ...state,
        getAreaList: {
          ...state.getAreaList,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case GET_AREA_LIST_FAIL:
      return {
        ...state,
        getAreaList: {
          ...state.getAreaList,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_GET_AREA_LIST ----------------
    case RESET_GET_AREA_LIST:
      return {
        ...state,
        getAreaList: initialState.getAreaList
      };

    // ---------------- UPDATE_AREA ----------------
    case UPDATE_AREA:
      return {
        ...state,
        updateArea: {
          ...state.updateArea,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case UPDATE_AREA_SUCCESS:
      return {
        ...state,
        updateArea: {
          ...state.updateArea,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case UPDATE_AREA_FAIL:
      return {
        ...state,
        updateArea: {
          ...state.updateArea,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_UPDATE_AREA ----------------
    case RESET_UPDATE_AREA:
      return {
        ...state,
        updateArea: initialState.updateArea
      };

    // ---------------- DELETE_AREA ----------------
    case DELETE_AREA:
      return {
        ...state,
        deleteArea: {
          ...state.deleteArea,
          loading: true,
          pending: true,
          hasError: false,
          data: []
        }
      };
    case DELETE_AREA_SUCCESS:
      return {
        ...state,
        deleteArea: {
          ...state.deleteArea,
          loading: false,
          pending: false,
          hasError: false,
          error: {},
          data: action.payload
        }
      };
    case DELETE_AREA_FAIL:
      return {
        ...state,
        deleteArea: {
          ...state.deleteArea,
          loading: false,
          pending: false,
          hasError: true,
          error: action.payload,
          data: []
        }
      };
    // ---------------- RESET_DELETE_AREA ----------------
    case RESET_DELETE_AREA:
      return {
        ...state,
        deleteArea: initialState.deleteArea
      };

    default:
      return state;
  }
};
