import notifications from "../Feedback/Notification";

const NotificationManager = (
  type: string,
  title: string = "Failed",
  error: string = "Something went wrong..!"
) => {
  notifications[type]({
    message: title,
    description: error
  });
};

export default NotificationManager;
