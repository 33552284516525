import Api from "./api";

export default class UserRequestAPI {
  static createUserAPI = (data: any) =>
    Api.request({
      method: "post",
      url: `user`,
      data
    });

  static updateUserAPI = (data: { userDTO: object; userID: string }) => {
    return Api.request({
      method: "put",
      url: `user/${data.userID}`,
      data: data?.userDTO
    });
  };

  static getUserAPI = (data: any) =>
    Api.request({
      method: "get",
      url: `user/${data}`,

      data
    });

  static getUserListAPI = (data: any) => {
    const URL = data?.role
      ? `user?role=${data?.role}&page=${data?.page}&name=${data?.name}&username=${data?.username}`
      : `user?page=${data?.page}&name=${data?.name}&username=${data?.username}`;
    return Api.request({
      method: "get",
      url: URL
    });
  };

  static getUserDropdownListAPI = () => {
    return Api.request({
      method: "get",
      url: `user?res=list`
    });
  };

  static deleteUserAPI = (data: any) =>
    Api.request({
      method: "delete",
      url: `user/${data.id}`
    });

  static getUserListByBUAndLevelAPI = (data: any) => {
    return Api.request({
      method: "get",
      url: `user?businessUnit=${data?.businessUnit}&roleLevel=${data?.level}&res=list`
    });
  };
}
